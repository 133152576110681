import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import HeaderDivider from "../../../Global/components/HeaderDivider";
import useMetaTags from '../../../Global/components/UseMetaTags';

function SingleNews() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    // Przyjmujemy slug jako część stanu lokalizacji, aby móc użyć go w metaTags
    const { slug } = location.state || {};

    const goBack = () => {
        navigate('/cyber-blog');
    }

    // Funkcja pomocnicza do wybierania treści w zależności od języka
    const getContent = (content, key) => {
        const languageSuffix = i18n.language === 'en' ? '_en' : i18n.language === 'de' ? '_de' : '';
        return content[key + languageSuffix] || content[key];
    };

    useEffect(() => {
        const fetchNewsItem = async () => {
            const options = {
                headers: {
                    'api-key': 'USR-d2a9cb36ec573eb9ef7d9931a4169e0425637834',
                }
            };

            try {
                const response = await axios.get('https://host675578.xce.pl/api/content/item/news/ff2165da633434402e0002d5', options);
                setData(response.data);
            } catch (error) {
                console.error('There was a problem with your axios operation:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsItem();
    }, [i18n.language]); // Dodajemy i18n.language jako zależność, aby odświeżyć dane przy zmianie języka

    // Aktualizujemy metatagi, aby korzystały z dynamicznie wybranej treści
    const metaTags = useMetaTags(
        `${getContent(data, 'title')} - Cyber Blog - BYTEBUILDERS`,
        `${getContent(data, 'content')}`,
        'strony www aktualnosci blog cyber blog projekty kreacje graficzne',
        `https://bytebuilders.pl/cyber-blog/${slug}`,
        `${data.url || ''}`
    );

    return (
        <>
            {metaTags}
            <section className="news">
                <HeaderDivider />
                <div className="news__inner">
                    <h2 className="news__single-title">{getContent(data, 'title')}</h2>
                    {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <>
                            <div className="news__single-wrapper" dangerouslySetInnerHTML={{ __html: getContent(data, 'content') }}></div>
                            <button onClick={goBack} className="news__single-button">{t('news_back_to_cyber_blog')}</button>
                        </>
                    )}
                </div>
            </section>
        </>
    );
}
  
export default SingleNews;