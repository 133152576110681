import { useTranslation } from 'react-i18next';
import JumpingLetters from '../../../../Global/animations/JumpingLetters';

function Banner() {
    const { t } = useTranslation();

    const handleBtnClick = () => {
        const section = document.getElementById('offer');
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 80, // Pozycja elementu względem początku strony
                behavior: 'smooth' // Gładkie przewijanie
            });
        }
    }
    return (
        <section className="banner">
            <div className="banner__inner">
                <div className="banner__wrapper">
                    <h1 className="banner__title">{t('banner_title')}</h1>
                    <h2 className="banner__subtitle">
                        <JumpingLetters text={t('banner_subtitle')} />
                        </h2>
                </div>
                <button className="banner__btn" onClick={handleBtnClick}>{t('banner_button')}</button>
            </div>
        </section>
    );
  }
  
export default Banner;