import logo from '../../assets/images//bb_logo.webp';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Nav from './components/Nav'
import MeetingModal from '../Modals/MeetingModal';
import { useTranslation } from 'react-i18next';

function Header() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isHamburgerActive, setIsHamburgerActive] = useState(false);
    const handleCloseHamburger = () => setIsHamburgerActive(false);
    const { t } = useTranslation();

    const handleCloseScroll = () => {
        const section = document.getElementById('contact');
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 50, // Pozycja elementu względem początku strony
                behavior: 'smooth' // Gładkie przewijanie
            });
        }
        setIsHamburgerActive(false);
    }

    const handleHamburger = () => {
        setIsHamburgerActive(!isHamburgerActive);
    }

    function openModal() {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

    return (
        <>
            <MeetingModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
            />
             <header className='header'>
                <div className='header__inner'>
                    <NavLink to='/' className='header__logo'>
                        <img src={logo} alt='ByteBuilders Logo' className='header__logo-img' />
                    </NavLink>
                    <Nav isHamburgerActive={isHamburgerActive} navType="desktop" onCloseScroll={handleCloseScroll}/>
                    <button className='header__hamburger' onClick={() => handleHamburger()}>
                        <div className={isHamburgerActive ? 'header__hamburger-line header__hamburger-line-top header__hamburger-line-top--active ' : 'header__hamburger-line header__hamburger-line-top'}></div>
                        <div className={isHamburgerActive ? 'header__hamburger-line header__hamburger-line-center header__hamburger-line-center--active' : 'header__hamburger-line header__hamburger-line-center'}></div>
                        <div className={isHamburgerActive ? 'header__hamburger-line header__hamburger-line-bottom header__hamburger-line-bottom--active' : 'header__hamburger-line header__hamburger-line-bottom'}></div>
                    </button>
                    <button className='header__btn' onClick={openModal}>{t('nav_button')}</button>
                </div>
                <Nav isHamburgerActive={isHamburgerActive} navType="mobile" onClose={handleCloseHamburger} onCloseScroll={handleCloseScroll}/>
            </header>
        </>
    );
  }
  
  export default Header;
  