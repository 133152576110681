import React, { useEffect, useState } from 'react';

function HeaderDivider() {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    // Pobieranie wysokości nagłówka po załadowaniu komponentu i przy każdej zmianie rozmiaru okna
    const updateHeight = () => {
      const header = document.querySelector('.header');
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    // Ustawienie początkowej wysokości
    updateHeight();

    // Dodanie nasłuchiwacza na zmianę rozmiaru okna, aby aktualizować wysokość gdy tylko zmieni się rozmiar okna
    window.addEventListener('resize', updateHeight);

    // Oczyszczenie nasłuchiwacza po odmontowaniu komponentu, aby uniknąć wycieków pamięci
    return () => window.removeEventListener('resize', updateHeight);
  }, []); // Pusta tablica zależności oznacza, że efekt uruchomi się tylko przy montowaniu i odmontowywaniu komponentu

  return (
    <div className="space" style={{ height: `${headerHeight + 10}px` }}></div>
  );
}

export default HeaderDivider;