import { useState, useEffect } from 'react';
import MeetingModal from '../../../../Modals/MeetingModal';
import ceoImage from '../../../../../assets/images/ceo.webp';
import { useTranslation } from 'react-i18next';

function CEO() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    function openModal() {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
    return () => {
        document.body.style.overflow = 'auto';
    };
    }, []);

    return (
        <>
            <MeetingModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
            />
            <section className="ceo">
                <div className="ceo__inner">
                    <div className="ceo_column-1">
                        <h2 className="ceo__title">{t('ceo_name')}</h2>
                        <p className="ceo__text">{t('ceo_desc')}</p>
                        <div className='ceo__wrapper'>
                            <a href="tel:+48695734954" className='ceo__link'>{t('ceo_phone')}</a>
                            <button className='ceo__btn' onClick={openModal}>{t('ceo_meet')}</button>
                        </div>
                    </div>
                    <div className="ceo_column-2">
                        <img src={ceoImage} alt="CEO Mateusz Tuczyński" className="ceo__image" />
                    </div>
                </div>
            </section>
        </>
    );
  }
  
export default CEO;