import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MeetingModal from '../../Modals/MeetingModal';
import { motion } from 'framer-motion';
import ArrowDown from '../../../assets/images/menu_arrow_down.svg';
import { useTranslation } from 'react-i18next';

function Nav(props) {    
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const navigationType = (props.navType === 'desktop' ? 'hide-mobile-nav' : 'hide-desktop-nav');
    const { t } = useTranslation();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    const submenuVariants = {
        open: { 
            opacity: 1, 
            height: "auto",
            transition: { 
                when: "beforeChildren",
                staggerChildren: 0.1,
                duration: 0.2
            }
        },
        closed: { 
            opacity: 0, 
            height: 0,
            transition: { duration: 0.2 }
        }
    };

    const listItemVariants = {
        open: { y: 0, opacity: 1 },
        closed: { y: -20, opacity: 0 }
    };

    const arrowVariants = {
        open: { rotate: 180, y: -1},
        closed: { rotate: 0, y: -1}
    };

    return (
        <>
            <MeetingModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
            />
            <nav className={props.isHamburgerActive ? `header__nav header__nav--active ${navigationType}` : `header__nav ${navigationType}`}>
                <ul className='header__nav-list'>
                    <li className='header__nav-item'>
                        <NavLink to='/' className='header__nav-link' onClick={props.onClose}>{t('nav_websites')}</NavLink>
                    </li>
                    <li className='header__nav-item'>
                        <NavLink to='/portfolio' className='header__nav-link' onClick={props.onClose}>{t('nav_portfolio')}</NavLink>
                    </li>
                    <li className='header__nav-item'>
                        <NavLink to='/cyber-blog' className='header__nav-link' onClick={props.onClose}>{t('nav_blog')}</NavLink>
                    </li>
                    <li 
                        className='header__nav-item'
                        onMouseEnter={() => setIsSubMenuOpen(true)}
                        onMouseLeave={() => setIsSubMenuOpen(false)}
                    >
                        <motion.button className='header__nav-link header__nav-item--offer' onClick={toggleSubMenu}>
                            {t('nav_offer')}
                            <motion.img src={ArrowDown} alt='Rozwiń menu'
                                className='header__nav-arrow'
                                variants={arrowVariants}
                                animate={isSubMenuOpen ? 'open' : 'closed'} />
                        </motion.button>
                        <motion.ul
                            className='header__nav-sublist'
                            variants={submenuVariants}
                            animate={isSubMenuOpen ? 'open' : 'closed'}
                            initial='closed'
                        >
                            <motion.li className='header__nav-subitem' variants={listItemVariants}>
                                <NavLink to='/strony-www' className='header__nav-link' onClick={props.onClose}>{t('nav_offer_website')}</NavLink>
                            </motion.li>
                            <motion.li className='header__nav-subitem' variants={listItemVariants}>
                                <NavLink to='/sklepy-internetowe' className='header__nav-link' onClick={props.onClose}>{t('nav_offer_shop')}</NavLink>
                                </motion.li>
                            <motion.li className='header__nav-subitem' variants={listItemVariants}>
                                <NavLink to='/kreacje-graficzne' className='header__nav-link' onClick={props.onClose}>{t('nav_offer_design')}</NavLink>
                            </motion.li>
                        </motion.ul>
                    </li>
                    <li className='header__nav-item'>
                        <button className='header__nav-link' onClick={props.onCloseScroll} >{t('nav_contact')}</button>
                    </li>
                    <li className='header__nav-item'>
                        <a href='tel:+48695734954' className='header__nav-link header__nav-item--phone'>Tel. 695 734 954</a>
                    </li>
                    <li className='header__nav-item'>
                        <button className='header__btn-mobile' onClick={openModal}>{t('nav_button')}</button>
                    </li>
                </ul>
            </nav>
        </>
    
    );
  }
  
  export default Nav;