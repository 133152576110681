import './sass/App.scss';
import { BrowserRouter as Router} from 'react-router-dom';
import Header from './modules/Header/Header';
import Content from './modules/Content/Content';
import Footer from './modules/Footer';
import './i18n';
import ChangeLanguageButton from './translations/changeLanguageButton';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Content />
        <Footer />
        <ChangeLanguageButton />
      </div>
    </Router>
  );
}

export default App;
