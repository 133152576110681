import React from 'react';
import PolandFlag from '../assets/images/poland_flag.svg';
import UKFlag from '../assets/images/uk_flag.svg';
import GermanyFlag from '../assets/images/germany_flag.svg';
import { useTranslation } from 'react-i18next';

function ChangeLanguageButton() {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const currentLanguage = i18n.language.split('-')[0]; // Uproszczony kod języka

    const languageButtons = [
        { code: 'pl', label: 'Polish', flag: PolandFlag },
        { code: 'en', label: 'English', flag: UKFlag },
        { code: 'de', label: 'German', flag: GermanyFlag },
    ];

    // Opcjonalnie: renderowanie wskaźnika ładowania, jeśli język nie został jeszcze ustalony
    if (!currentLanguage) {
        return <div>Loading...</div>; // Możesz tutaj dodać lepszy wskaźnik ładowania
    }

    return (
        <div className='change-language-buttons'>
            {languageButtons.filter(btn => btn.code !== currentLanguage).map(({ code, flag }) => (
                <button key={code} onClick={() => changeLanguage(code)}>
                    <img src={flag} alt={`${code} Flag`} />
                </button>
            ))}
        </div>
    );
}

export default ChangeLanguageButton;