import React, { useState } from 'react';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Ustawienie elementu root dla dostępności (a11y)
Modal.setAppElement('#root');

const MeetingModal = ({ modalIsOpen, closeModal, contentLabel = "Umów spotkanie", serviceName }) => {
    const [isSend, setIsSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        serviceName: Yup.string(),
        name: Yup.string().required(t('modal_pricing_req1')),
        email: Yup.string().email(t('modal_pricing_req3')).required(t('modal_pricing_req2')),
        tel: Yup.string().required(t('modal_pricing_req4')),
        message: Yup.string().required(t('modal_pricing_req5')),
        consent: Yup.array().min(1, t('modal_pricing_req6')).required(t('modal_pricing_req6')),
      });

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        axios.post('https://khbgdcdtyr.cfolks.pl/wp-json/api/v1/email-pricing/', values)
        .then(response => {
            setLoading(false);
            setIsSend(true);
            resetForm();
            setSubmitting(false);
        })
        .catch(error => {
            // Tutaj obsłuż błąd
            console.error('Błąd wysyłania: ', error);
        });
      };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel={contentLabel}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Przezroczystość tła
                    zIndex: 9999999999, // z-index dla tła
                    backdropFilter: 'blur(5px)', // Rozmycie tła
                    },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999999999,
                    borderRadius: 0,
                    border: 'none',
                    backgroundColor: '#f1b808',
                    padding: '30px',
                },
            }}
        >
            <div className='modal__inner-wrapper'>
                <h2 className='meeting-form__title modal-pricing'>{t('modal_pricing_title')}</h2>
                <Formik
                    initialValues={{
                        serviceName: serviceName,
                        name: '',
                        email: '',
                        tel: '',
                        message: '',
                        consent: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    >
                    {({ isSubmitting, handleChange, values }) => (
                        <Form>
                        <div className='form-service'>
                            <Field type="text" name="serviceName" disabled/>
                        </div>
                        <div className='meeting-form__inputs'>
                            <div>
                                <label htmlFor="name">{t('modal_pricing_label_name')}</label>
                                <Field type="text" name="name" />
                                <ErrorMessage name="name" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <label htmlFor="email">{t('modal_pricing_label_email')}</label>
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <label htmlFor="tel">{t('modal_pricing_label_phone')}</label>
                                <Field type="tel" name="tel" />
                                <ErrorMessage name="tel" component="div" className='meeting-form__error'/>
                            </div>
                        </div>

                        <div className='textarea__wrapper'>
                            <label htmlFor="message">{t('modal_pricing_label_message')}</label>
                            <Field as="textarea" name="message" />
                            <ErrorMessage name="message" component="div" className='meeting-form__error'/>
                        </div>

                        <div className='meeting-form__days meeting-form__consent'>
                            <label>
                                <Field type="checkbox" name="consent" value="consent" onChange={handleChange} checked={values.consent.includes('consent')} />
                                <span className="custom-checkbox-mark"></span>
                                <p 
                                style={{display: 'inline'}}
                                data-tooltip-id="consent-tooltip"
                                data-tooltip-content={t('modal_pricing_label_con_tooltip')}
                                data-tooltip-place="top"
                                >
                                    {t('modal_pricing_label_con')}
                                </p>
                            </label>
                            <Tooltip id="consent-tooltip" className="consent-tooltip" />
                        </div>
                        <ErrorMessage name="consent" component="div" className='meeting-form__error'/>
                        
                        <div className='meeting-form__buttons'>
                            <button type="submit" disabled={isSubmitting}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{display: loading ? 'inline-block' : 'none', marginRight: '5px'}}
                                />
                                {t('modal_pricing_button')}
                            </button>
                            <button onClick={closeModal} className='meeting-form__close'>X</button>
                        </div>
                        </Form>
                    )}
                </Formik>
                {isSend &&
                    <div className='meeting-form__status'>
                        <p>{t('modal_pricing_success')}</p>
                    </div>
                }
            </div>
        </Modal>
    )
};

export default MeetingModal;
