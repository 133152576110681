import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HeaderDivider from "../../../Global/components/HeaderDivider";
import useMetaTags from '../../../Global/components/UseMetaTags';
import ItemAnimation from '../../../Global/animations/ItemAnimation';
import OgImage from '../../../../assets/images/og_bg.webp';

function News() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const metaTags = useMetaTags(
        t('meta_tag_news_title'),
        t('meta_tag_news_desc'),
        t('meta_tag_news_keywords'),
        'https://bytebuilders.pl/cyber-blog',
        `https://bytebuilders.pl${OgImage}`
    );

    const slugify = (text) => {
        return text
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .trim()
          .replace(/\s+/g, '-')
          .replace(/[^\w-]+/g, '');
    };

    const goToSingleNews = (title, id, url, content) => {
        const slug = slugify(title);
        navigate(`/cyber-blog/${slug}`, { state: { id: id, title: title, url: url, content: content, slug: slug } });
    }

    const getContent = (news, key) => {
        const languageSuffix = i18n.language === 'en' ? '_en' : i18n.language === 'de' ? '_de' : '';
        return news[key + languageSuffix] || news[key];
    };

    useEffect(() => {
        const fetchNews = async () => {
            const options = {
                headers: {
                    'api-key': 'USR-d2a9cb36ec573eb9ef7d9931a4169e0425637834',
                }
            };
            try {
                const response = await axios.get('https://host675578.xce.pl/api/content/items/news', options);
                setData(response.data.reverse());
            } catch (error) {
                console.error('There was a problem with your axios operation:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    return (
        <>
            {metaTags}
            <section className="news">
                <HeaderDivider />
                <div className="news__inner">
                    <h2 className="news__title">{t('cyber_blog_title')}</h2>
                    {loading ? (
                        <Spinner animation="border" role="status" style={{marginTop: '2rem'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <div className="news__wrapper">
                            {data.map((news) => (
                                <ItemAnimation key={news._id}>
                                    <div className="news__column">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src={`https://host675578.xce.pl/storage/uploads${news.image.path}`} />
                                            <Card.Body>
                                                <Card.Title>{getContent(news, 'title')}</Card.Title>
                                                <Card.Text>
                                                    {getContent(news, 'content').replace(/<\/?[^>]+(>|$)/g, "").length > 140 ? getContent(news, 'content').replace(/<\/?[^>]+(>|$)/g, "").substring(0, 140) + '...' : getContent(news, 'content').replace(/<\/?[^>]+(>|$)/g, "")}
                                                </Card.Text>
                                                <Button onClick={() => goToSingleNews(getContent(news, 'title'), news._id, `https://host675578.xce.pl/storage/uploads${news.image.path}`, getContent(news, 'content'))} style={{ backgroundColor: '#f1b808', borderColor: '#f1b808', color: '#000', fontFamily: 'Reddit Mono', borderRadius: '0', fontSize: '0.9rem', textTransform: 'uppercase', padding: '0.5rem 1rem', fontWeight: '300'}}>
                                                    {t('news_read_more')}
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </ItemAnimation>
                            ))}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default News;