import React, { useState, useEffect } from 'react';
import HeaderDivider from "../../../Global/components/HeaderDivider";
import useMetaTags from '../../../Global/components/UseMetaTags';
import ArrowDownIcon from '../../../../assets/images/arrow_down.svg';
import Timeline from './components/Timeline';
import RowImage from '../../../../assets/images/stronywww_image.webp';
import PricingMessageModal from '../../../Modals/PricingMessageModal';
import OgImage from '../../../../assets/images/og_bg.webp';
import { useTranslation } from 'react-i18next';

function StronyWWW() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [service, setService] = useState('');
    const { t } = useTranslation();

    function openModal(servNa) {
        setIsOpen(true);
        setService(servNa);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    }

    const metaTags = useMetaTags(
        t('meta_tag_website_title'),
        t('meta_tag_website_desc'),
        t('meta_tag_website_keywords'),
        'https://bytebuilders.pl/strony-www',
        `https://bytebuilders.pl${OgImage}`
    );

    const handleArrowClick = () => {
        const section = document.getElementById('tiles');
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 100, // Pozycja elementu względem początku strony
                behavior: 'smooth' // Gładkie przewijanie
            });
        }
    }

    useEffect(() => {
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, []);

    return (
      <>
        {metaTags}
        <PricingMessageModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            serviceName={service}
        />
        <section className="offer-item">
            <HeaderDivider />
            <div className="offer-item__inner">
                <h1 className="offer-item__title">{t('offer_item_title')} <span className="offer-item__brandColor">{t('offer_item_color')}</span></h1>
                <p className="offer-item__desc">{t('offer_item_desc')}</p>
                <p className="offer-item__desc2">{t('offer_item_desc2')}</p>
                <div className="offer-item__arrow-down-wrapper">
                    <button onClick={handleArrowClick} className="offer-item__btn-arrow">
                        <img src={ArrowDownIcon} alt="Strzałka w dół" />
                    </button>
                </div>
                <div className="offer-item__row">
                    <img src={RowImage} alt="Strony WWW" className="offer-item__row-image" />
                    <div className="offer-item__row-content">
                        <h2 className="offer-item__row-title">{t('offer_item_row_title')}</h2>
                        <p className="offer-item__row-desc">{t('offer_item_row_desc')}</p>
                    </div>
                </div>
                <Timeline />
                <div className="offer-item__wrapper-tiles" id="tiles">
                    <div className="offer-item__tile">
                        <p className="offer-item__tile-title">{t('offer_item_tile1_title')}</p>
                        <p className="offer-item__tile-subtitle">{t('offer_item_tile1_subtitle')}</p>
                        <ul className="offer-item__tile-list">
                            <li>{t('offer_item_tile1_list_li1')}</li>
                            <li>{t('offer_item_tile1_list_li2')}</li>
                            <li>{t('offer_item_tile1_list_li3')}</li>
                        </ul>
                        <button className="offer-item__btn" onClick={() => openModal(t('offer_item_modal_title1'))}>{t('offer_item_tile1_button')}</button>
                    </div>
                    <div className="offer-item__tile">
                        <p className="offer-item__tile-title">{t('offer_item_tile2_title')}</p>
                        <p className="offer-item__tile-subtitle">{t('offer_item_tile2_subtitle')}</p>
                        <ul className="offer-item__tile-list">
                            <li>{t('offer_item_tile2_list_li1')}</li>
                            <li>{t('offer_item_tile2_list_li2')}</li>
                            <li>{t('offer_item_tile2_list_li3')}</li>
                        </ul>
                        <button className="offer-item__btn" onClick={() => openModal(t('offer_item_modal_title2'))}>{t('offer_item_tile2_button')}</button>
                    </div>
                    <div className="offer-item__tile">
                        <p className="offer-item__tile-title">{t('offer_item_tile3_title')}</p>
                        <p className="offer-item__tile-subtitle">{t('offer_item_tile3_subtitle')}</p>
                        <ul className="offer-item__tile-list">
                            <li>{t('offer_item_tile3_list_li1')}</li>
                            <li>{t('offer_item_tile3_list_li2')}</li>
                            <li>{t('offer_item_tile3_list_li3')}</li>
                        </ul>
                        <button className="offer-item__btn" onClick={() => openModal(t('offer_item_modal_title3'))}>{t('offer_item_tile3_button')}</button>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default StronyWWW;