import React, { useState } from 'react';
import Modal from 'react-modal';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Ustawienie elementu root dla dostępności (a11y)
Modal.setAppElement('#root');

const MeetingModal = ({ modalIsOpen, closeModal, contentLabel = "Umów spotkanie" }) => {
    const [isSend, setIsSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('modal_meet_req1')),
        email: Yup.string().email(t('modal_meet_req3')).required(t('modal_meet_req2')),
        tel: Yup.string().required(t('modal_meet_req4')),
        hours: Yup.array().min(1, t('modal_meet_req5')).required(t('modal_meet_req5')),
        days: Yup.array().min(1, t('modal_meet_req6')).required(t('modal_meet_req6')),
        consent: Yup.array().min(1, t('modal_meet_req7')).required(t('modal_meet_req7')),
      });

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        axios.post('https://khbgdcdtyr.cfolks.pl/wp-json/api/v1/email-meet/', values)
        .then(response => {
            setLoading(false);
            setIsSend(true);
            resetForm();
            setSubmitting(false);
        })
        .catch(error => {
            // Tutaj obsłuż błąd
            console.error('Błąd wysyłania: ', error);
        });
      };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel={contentLabel}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Przezroczystość tła
                    zIndex: 9999999999, // z-index dla tła
                    backdropFilter: 'blur(5px)', // Rozmycie tła
                    },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999999999,
                    borderRadius: 0,
                    border: 'none',
                    backgroundColor: '#f1b808',
                    padding: '30px',
                },
            }}
        >
            <div className='modal__inner-wrapper'>
                <h2 className='meeting-form__title'>{t('modal_meet_title')}</h2>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        tel: '',
                        hours: [],
                        days: [],
                        consent: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    >
                    {({ isSubmitting, handleChange, values }) => (
                        <Form>
                        <div className='meeting-form__inputs'>
                            <div>
                                <label htmlFor="name">{t('moadl_meet_label_name')}</label>
                                <Field type="text" name="name" />
                                <ErrorMessage name="name" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <label htmlFor="email">{t('moadl_meet_label_email')}</label>
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <label htmlFor="tel">{t('moadl_meet_label_phone')}</label>
                                <Field type="tel" name="tel" />
                                <ErrorMessage name="tel" component="div" className='meeting-form__error'/>
                            </div>
                        </div>

                        <h3 className='meeting-form__subtitle'>{t('moadl_meet_label_title1')}</h3>
                        
                        <div className='meeting-form__hours'>
                            <div>
                                <label>
                                <Field type="checkbox" name="hours" value="8:00 - 10:00" onChange={handleChange} checked={values.hours.includes('8:00 - 10:00')} />
                                <span className="custom-checkbox-mark"></span>
                                8:00 - 10:00
                                </label>
                            </div>

                            <div>
                                <label>
                                <Field type="checkbox" name="hours" value="10:00 - 12:00" onChange={handleChange} checked={values.hours.includes('10:00 - 12:00')} />
                                <span className="custom-checkbox-mark"></span>
                                10:00 - 12:00
                                </label>
                            </div>

                            <div>
                                <label>
                                <Field type="checkbox" name="hours" value="12:00 - 14:00" onChange={handleChange} checked={values.hours.includes('12:00 - 14:00')} />
                                <span className="custom-checkbox-mark"></span>
                                12:00 - 14:00
                                </label>
                            </div>

                            <div>
                                <label>
                                <Field type="checkbox" name="hours" value="14:00 - 16:00" onChange={handleChange} checked={values.hours.includes('14:00 - 16:00')} />
                                <span className="custom-checkbox-mark"></span>
                                14:00 - 16:00
                                </label>
                            </div>

                            <div>
                                <label>
                                <Field type="checkbox" name="hours" value="16:00 - 18:00" onChange={handleChange} checked={values.hours.includes('16:00 - 18:00')} />
                                <span className="custom-checkbox-mark"></span>
                                16:00 - 18:00
                                </label>
                            </div>
                        </div>
                        <ErrorMessage name="hours" component="div" className='meeting-form__error' />

                        <h3 className='meeting-form__subtitle'>{t('moadl_meet_label_title2')}</h3>

                        <div className='meeting-form__days'>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Poniedziałek" onChange={handleChange} checked={values.days.includes('Poniedziałek')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_label_mon')}
                                </label>
                            </div>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Wtorek" onChange={handleChange} checked={values.days.includes('Wtorek')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_label_tue')}
                                </label>
                            </div>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Środa" onChange={handleChange} checked={values.days.includes('Środa')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_label_wed')}
                                </label>
                            </div>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Czwartek" onChange={handleChange} checked={values.days.includes('Czwartek')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_label_thu')}
                                </label>
                            </div>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Piątek" onChange={handleChange} checked={values.days.includes('Piątek')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_label_fri')}
                                </label>
                            </div>
                            <div>
                                <label>
                                <Field type="checkbox" name="days" value="Sobota" onChange={handleChange} checked={values.days.includes('Sobota')} />
                                <span className="custom-checkbox-mark"></span>
                                {t('modal_meet_lanel_sat')}
                                </label>
                            </div>
                        </div>
                        <ErrorMessage name="days" component="div" className='meeting-form__error' />

                        <div className='meeting-form__days meeting-form__consent'>
                            <label>
                                <Field type="checkbox" name="consent" value="consent" onChange={handleChange} checked={values.consent.includes('consent')} />
                                <span className="custom-checkbox-mark"></span>
                                <p 
                                style={{display: 'inline'}}
                                data-tooltip-id="consent-tooltip"
                                data-tooltip-content={t('modal_meet_label_con_tooltip')}
                                data-tooltip-place="top"
                                >
                                    {t('modal_meet_label_con')}
                                </p>
                            </label>
                            <Tooltip id="consent-tooltip" className="consent-tooltip" />
                        </div>
                        <ErrorMessage name="consent" component="div" className='meeting-form__error'/>
                        
                        <div className='meeting-form__buttons'>
                            <button type="submit" disabled={isSubmitting}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{display: loading ? 'inline-block' : 'none', marginRight: '5px'}}
                                />
                                {t('moadl_meet_button')}
                            </button>
                            <button onClick={closeModal} className='meeting-form__close'>X</button>
                        </div>
                        </Form>
                    )}
                </Formik>
                {isSend &&
                    <div className='meeting-form__status'>
                        <p>{t('modal_meet_success')}</p>
                    </div>
                }
            </div>
        </Modal>
    )
};

export default MeetingModal;
