import { Helmet } from 'react-helmet';

// Ta funkcja jest hookiem, który zwraca komponent Helmet skonfigurowany z przekazanymi meta tagami.
const useMetaTags = (title, description, keywords, url, image) => {
  // React Helmet zajmie się aktualizacją metadanych w <head>
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default useMetaTags;