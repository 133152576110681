import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ItemAnimation = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Wywołaj tylko raz
    threshold: 0.2, // Element musi być widoczny w 20% przed uruchomieniem animacji
  });

  const variants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 50 }, // Zaczyna lekko poniżej swojej pozycji i jest nieprzejrzysty
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      {children}
    </motion.div>
  );
};

export default ItemAnimation;