import React, { useState, useEffect } from 'react';
import HeaderDivider from "../../../Global/components/HeaderDivider";
import useMetaTags from '../../../Global/components/UseMetaTags';
import ArrowDownIcon from '../../../../assets/images/arrow_down.svg';
import Timeline from '../sklepy-internetowe/components/Timeline';
import RowImage from '../../../../assets/images/sklepinter_image.webp';
import PricingMessageModal from '../../../Modals/PricingMessageModal';
import OgImage from '../../../../assets/images/og_bg.webp';
import { useTranslation } from 'react-i18next';

function SklepyInternetowe() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [service, setService] = useState('');
    const { t } = useTranslation();

    function openModal(servNa) {
        setIsOpen(true);
        setService(servNa);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    }

    const metaTags = useMetaTags(
        t('meta_tag_shop_title'),
        t('meta_tag_shop_desc'),
        t('meta_tag_shop_keywords'),
        'https://bytebuilders.pl/sklepy-internetowe',
        `https://bytebuilders.pl${OgImage}`
    );

    const handleArrowClick = () => {
      const section = document.getElementById('tiles');
      if (section) {
          window.scrollTo({
              top: section.offsetTop - 100, // Pozycja elementu względem początku strony
              behavior: 'smooth' // Gładkie przewijanie
          });
      }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      {metaTags}
      <PricingMessageModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          serviceName={service}
      />
      <section className="offer-item">
          <HeaderDivider />
          <div className="offer-item__inner">
            <h1 className="offer-item__title">{t('shops_specialization')} <span className="offer-item__brandColor">{t('shops_specialization_color')}</span></h1>
            <p className="offer-item__desc">{t('shops_desc')}</p>
            <p className="offer-item__desc2">{t('shops_desc2')}</p>
            <div className="offer-item__arrow-down-wrapper">
                <button onClick={handleArrowClick} className="offer-item__btn-arrow">
                    <img src={ArrowDownIcon} alt={t('shops_arrow_alt')} />
                </button>
            </div>
            <div className="offer-item__row">
              <img src={RowImage} alt={t('shops_row_image_alt')} className="offer-item__row-image" />
              <div className="offer-item__row-content">
                  <h2 className="offer-item__row-title">{t('shops_row_title')}</h2>
                  <p className="offer-item__row-desc">{t('shops_row_desc')}</p>
              </div>
            </div>
            <Timeline />
            <div className="offer-item__wrapper-tiles" id="tiles">
                  <div className="offer-item__tile">
                      <p className="offer-item__tile-title">{t('shops_tile_basic_title')}</p>
                      <p className="offer-item__tile-subtitle">{t('shops_tile_basic_subtitle')}</p>
                      <ul className="offer-item__tile-list">
                          <li>{t('shops_tile_basic_li1')}</li>
                      </ul>
                      <button className="offer-item__btn" onClick={() => openModal(t('shops_modal_basic_title'))}>{t('shops_tile_button')}</button>
                  </div>
                  <div className="offer-item__tile">
                      <p className="offer-item__tile-title">{t('shops_tile_premium_title')}</p>
                      <p className="offer-item__tile-subtitle">{t('shops_tile_premium_subtitle')}</p>
                      <ul className="offer-item__tile-list">
                          <li>{t('shops_tile_premium_li1')}</li>
                      </ul>
                      <button className="offer-item__btn" onClick={() => openModal(t('shops_modal_premium_title'))}>{t('shops_tile_button')}</button>
                  </div>
                  <div className="offer-item__tile">
                      <p className="offer-item__tile-title">{t('shops_tile_pro_title')}</p>
                      <p className="offer-item__tile-subtitle">{t('shops_tile_pro_subtitle')}</p>
                      <ul className="offer-item__tile-list">
                          <li>{t('shops_tile_pro_li1')}</li>
                      </ul>
                      <button className="offer-item__btn" onClick={() => openModal(t('shops_modal_pro_title'))}>{t('shops_tile_button')}</button>
                  </div>
              </div>
          </div>
      </section>
    </>
  );
  }
  
  export default SklepyInternetowe;