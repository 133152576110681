import { Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import Home from '../Content/pages/home/Home';
import Portfolio from '../Content/pages/portfolio/Portfolio';
import News from '../Content/pages/news/News';
import SingleNews from '../Content/pages/news/SingleNews';
import StronyWWW from '../Content/pages/strony-www/StronyWWW';
import SklepyInternetowe from '../Content/pages/sklepy-internetowe/SklepyInternetowe';
import KreacjeGraficzne from '../Content/pages/kreacje-graficzne/KreacjeGraficzne';
import PrivacyPolicy from '../Content/pages/privacy-policy/PrivacyPolicy';

// Komponent Animacji
const PageTransition = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.div>
);

function Content() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        {/* MENU */}
        <Route path="/" element={<PageTransition><Home /></PageTransition>} />
        <Route path="/portfolio" element={<PageTransition><Portfolio /></PageTransition>} />
        <Route path="/cyber-blog" element={<PageTransition><News /></PageTransition>} />
        <Route path="/cyber-blog/:id" element={<PageTransition><SingleNews /></PageTransition>} />
        <Route path="/polityka-prywatnosci" element={<PageTransition><PrivacyPolicy /></PageTransition>} />
        
        {/* OFERTA */}
        <Route path="/strony-www" element={<PageTransition><StronyWWW /></PageTransition>} />
        <Route path="/sklepy-internetowe" element={<PageTransition><SklepyInternetowe /></PageTransition>} />
        <Route path="/kreacje-graficzne" element={<PageTransition><KreacjeGraficzne /></PageTransition>} />
      </Routes>
    </AnimatePresence>
  );
}

export default Content;