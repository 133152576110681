import useMetaTags from '../../../Global/components/UseMetaTags';
import Banner from './components/Banner';
import AboutUs from './components/AboutUs';
import CEO from './components/CEO';
import Offer from './components/Offer';
import FAQ from './components/FAQ';
import OgImage from '../../../../assets/images/og_bg.webp';
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    const metaTags = useMetaTags(
        t('meta_tag_home_title'),
        t('meta_tag_home_desc'),
        t('meta_tag_home_keywords'),
        'https://bytebuilders.pl',
        `https://bytebuilders.pl${OgImage}`
    );

    return (
        <>
            {metaTags}
            <Banner />
            <AboutUs />
            <CEO />
            <Offer />
            <FAQ />
        </>
    );
  }
  
export default Home;