import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

function FAQ() {
    const { t } = useTranslation();

    return (
         <section className="faq">
            <div className='faq__inner'>
                <h2 className='faq__title'>{t('faq_title')}</h2>
                <Accordion defaultActiveKey="0" flush className="custom-accordion">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t('faq_item1_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item1_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>{t('faq_item2_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item2_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>{t('faq_item3_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item3_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>{t('faq_item4_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item4_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>{t('faq_item5_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item5_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>{t('faq_item6_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item6_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>{t('faq_item7_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item7_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>{t('faq_item8_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item8_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>{t('faq_item9_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item9_text')}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>{t('faq_item10_title')}</Accordion.Header>
                        <Accordion.Body>
                        {t('faq_item10_text')}
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>
        </section>
    );
  }
  
  export default FAQ;