import HeaderDivider from '../../../Global/components/HeaderDivider';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useMetaTags from '../../../Global/components/UseMetaTags';
import Spinner from 'react-bootstrap/Spinner';
import ItemAnimation from '../../../Global/animations/ItemAnimation';
import OgImage from '../../../../assets/images/og_bg.webp';
import { useTranslation } from 'react-i18next';
import ModalImage from '../../../Global/components/ModalImage';

function Portfolio() {
    const [data, setData] = useState([]);
    const [dataGraphicCreations, setDataGraphicCreations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingGraphicCreations, setLoadingGraphicCreations] = useState(true);
    const { t } = useTranslation();

    const metaTags = useMetaTags(
      t('meta_tag_portfolio_title'),
      t('meta_tag_portfolio_desc'),
      t('meta_tag_portfolio_keywords'),
      'https://bytebuilders.pl/portfolio',
      `https://bytebuilders.pl${OgImage}`
    );

    useEffect(() => {
        const options = {
            headers: {
              'api-key': 'USR-d2a9cb36ec573eb9ef7d9931a4169e0425637834',
            }
          };

        setTimeout(() => {
          axios.get('https://host675578.xce.pl/api/content/items/projects', options)
          .then(response => {
            // Kopiowanie i odwracanie danych przed zapisaniem do stanu
            const reversedData = [...response.data].reverse();
            setData(reversedData);
            setLoading(false);
          })
          .catch(error => console.error('There was a problem with your axios operation:', error));
          setLoading(false);
        }, 500);

        setTimeout(() => {
          axios.get('https://host675578.xce.pl/api/content/items/graphiccreations', options)
          .then(response => {
            // Kopiowanie i odwracanie danych przed zapisaniem do stanu
            const reversedData = [...response.data].reverse();
            setDataGraphicCreations(reversedData);
            setLoadingGraphicCreations(false);
          })
          .catch(error => console.error('There was a problem with your axios operation:', error));
          setLoading(false);
        }, 500);

      }, []);

      
    return (
      <>
        {metaTags}
        <section className="portfolio">
          <HeaderDivider />
          <div className="portfolio__inner">
              <h2 className="portfolio__title">Portfolio</h2>
              {loading && 
                <Spinner animation="border" role="status" style={{marginTop: '2rem', marginBottom: '2rem'}}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              }
              {!loading && (
                
                  <div className="portfolio__wrapper">
                    {data.map((project) => (
                      <ItemAnimation key={project._id}>
                        <div className="portfolio__column">
                            <img src={`https://host675578.xce.pl/storage/uploads${project.mobile_image.path}`} alt={project.name} className="portfolio__column-image"/>
                            <h3 className="portfolio__column-title">{project.name}</h3>
                            <a href={project.link} target='_blank' className="portfolio__column-link" rel="noreferrer">{t('portfolio_button_check_realisation')}</a>
                        </div>
                      </ItemAnimation>
                    ))}
                  </div>
              )}
              <h2 className="portfolio__title">Kreacje graficzne</h2>
              {loadingGraphicCreations && 
                <Spinner animation="border" role="status" style={{marginTop: '2rem'}}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              }
              {!loadingGraphicCreations && (
                
                  <div className="portfolio__wrapper">
                    {dataGraphicCreations.map((project) => (
                      <ItemAnimation key={project._id}>
                        <div className="portfolio__graphiccreation">
                            <ModalImage src={`https://host675578.xce.pl/storage/uploads${project.image.path}`} alt={project.name} />
                        </div>
                      </ItemAnimation>
                    ))}
                  </div>
              )}
          </div>
        </section>
      </>
    );
  }
  
  export default Portfolio;