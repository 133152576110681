import React from 'react';
import { motion } from 'framer-motion';

const DancingLetters = ({ text }) => {
  // Rozbij tekst na litery
  const letters = Array.from(text);

  // Definicja wariantów animacji dla liter
  const letterVariants = {
    dance: i => ({
      y: [0, -10, 0, 10, 0], // Ruch w górę i w dół
      transition: {
        duration: 2 + i * 0.1, // Każda litera ma nieco inne tempo
        ease: "easeInOut",
        repeat: Infinity, // Nieskończona animacja
      },
    }),
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {letters.map((letter, i) => (
        <motion.span
          key={i}
          custom={i}
          animate="dance"
          variants={letterVariants}
          style={{ display: 'inline-block' }}
        >
          {letter}
        </motion.span>
      ))}
    </div>
  );
};

export default DancingLetters;