import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ContactPicture from '../../../assets/images/contact_picture.svg';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function ContactSection() {
    const [isSend, setIsSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('contact_req1')),
        email: Yup.string().email(t('contact_req3')).required(t('contact_req2')),
        tel: Yup.string().required(t('contact_req4')),
        message: Yup.string().required(t('contact_req5')),
        consent: Yup.array().min(1, t('contact_req6')).required(t('contact_req6')),
    });

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        axios.post('https://khbgdcdtyr.cfolks.pl/wp-json/api/v1/email-contact/', values)
        .then(response => {
            setLoading(false);
            resetForm();
            setIsSend(true);
            setSubmitting(false);
        })
        .catch(error => {
            // Tutaj obsłuż błąd
            console.error('Błąd wysyłania: ', error);
        });
      };

    return (
      <section className="contact" id='contact'>
        <div className="contact__inner">
            <h2 className='contact__title'>{t('conact_title')} <span className='contact__title-span'>{t('contact_title_span')}</span></h2>
            <div className="contact__column-1">
                <p className='contact__text'>{t('contact_text')}</p>
                <img className='contact__image' src={ContactPicture} alt='Kontakt obraz' />
            </div>
            <div className="contact__column-2">
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    tel: '',
                    message: '',
                    consent: [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                {({ isSubmitting, handleChange, values }) => (
                    <Form>
                        <div className='contact-form-wrapper'>
                            <div>
                                <Field type="text" name="name" placeholder={t('contact_label_name')} />
                                <ErrorMessage name="name" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <Field type="email" name="email" placeholder={t('contact_label_email')} />
                                <ErrorMessage name="email" component="div" className='meeting-form__error' />
                            </div>
                            <div>
                                <Field type="tel" name="tel" placeholder={t('contact_label_phone')} />
                                <ErrorMessage name="tel" component="div" className='meeting-form__error'/>
                            </div>
                            <div>
                                <Field name="message" as="textarea" placeholder={t('contact_label_message')} />
                                <ErrorMessage name="message" component="div" className='meeting-form__error'/>
                            </div>
                            <div className='contact__consent'>
                                <label>
                                    <Field type="checkbox" name="consent" value="consent" onChange={handleChange} checked={values.consent.includes('consent')} />
                                    <span className="custom-checkbox-mark"></span>
                                    <p 
                                    style={{display: 'inline'}}
                                    data-tooltip-id="consent-tooltip"
                                    data-tooltip-content={t('contact_label_con_tooltip')}
                                    data-tooltip-place="top"
                                    >
                                        {t('contact_label_con')}
                                    </p>
                                </label>
                                <Tooltip id="consent-tooltip" className="consent-tooltip" />
                                
                                <ErrorMessage name="consent" component="div" className='meeting-form__error'/>
                            </div>
                            
                            <button type="submit" className='contact__submit' disabled={isSubmitting}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{display: loading ? 'inline-block' : 'none', marginRight: '5px'}}
                            />
                                {t('contact_button')}
                                </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {isSend &&
                <div className='contact__status'>
                    <p>{t('contact_success')}</p>
                </div>
            }
            </div>
        </div>
      </section>
    );
  }
  
export default ContactSection;