import { NavLink } from 'react-router-dom';
import fbIcon from '../assets/images/fb_icon.svg';
import igIcon from '../assets/images/ig_icon.svg';
import ContactSection from './Global/layout/ContactSection';
import { useTranslation } from 'react-i18next';

function Footer() {
    const date = new Date();
    const { t } = useTranslation();

    const handleBtnClick = () => {
      const section = document.getElementById('contact');
      if (section) {
          window.scrollTo({
              top: section.offsetTop - 50, // Pozycja elementu względem początku strony
              behavior: 'smooth' // Gładkie przewijanie
          });
      }
  }

    return (
      <>
        <ContactSection />
        <div id='map'></div>
        <footer className="footer">
        <div className="footer__inner">

         <div className="footer__column">
            <h3 className="footer__column-title">{t('footer_column1_title')}</h3>
            <div className="footer__column-wrapper">
              <p className="footer__column-text">{t('footer_column1_text1')}</p>
              <p className="footer__column-text">{t('footer_column1_text2')}</p>
              <p className="footer__column-text">{t('footer_column1_text3')}</p>
            </div>
            <div className="footer__column-wrapper">
              <a href="tel:+48695734954" className="footer__column-link">+48 695 734 954</a>
              <a href="mailto:kontakt@bytebuilders.pl" className="footer__column-link">kontakt@bytebuilders.pl</a>
            </div>
         </div>

         <div className="footer__column">
          <h3 className="footer__column-title">{t('footer_column2_title')}</h3>
          <div className="footer__column-wrapper">
            <NavLink to="/" className="footer__column-link">{t('footer_column2_text1')}</NavLink>
            <NavLink to="/portfolio" className="footer__column-link">{t('footer_column2_text2')}</NavLink>
            <NavLink to="/cyber-blog" className="footer__column-link">{t('footer_column2_text3')}</NavLink>
            <button className="footer__column-link" onClick={handleBtnClick}>{t('footer_column2_text4')}</button>
            <NavLink to="/polityka-prywatnosci" className="footer__column-link">{t('footer_column3_title')}</NavLink>
          </div>
         </div>

         <div className="footer__column">
          <h3 className="footer__column-title">{t('footer_column4_titl1e')}</h3>
          <div className="footer__column-wrapper">
            <NavLink to="/strony-www" className="footer__column-link">{t('footer_column4_text1')}</NavLink>
            <NavLink to="/sklepy-internetowe" className="footer__column-link">{t('footer_column4_text2')}</NavLink>
            <NavLink to="/kreacje-graficzne" className="footer__column-link">{t('footer_column4_text3')}</NavLink>
          </div>
         </div>

         <div className="footer__column">
          <h3 className="footer__column-title">{t('footer_column5_title')}</h3>
          <div className="footer__column-wrapper-icons">
            <a href="https://www.facebook.com/profile.php?id=100092579140475" target='_blank' className='footer__column-media' rel="noreferrer">
              <img src={fbIcon} alt="Facebook" className='footer__column-media-icon'/>
            </a>
            <a href="https://www.instagram.com/pl_bytebuilders.pl?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank' className='footer__column-media' rel="noreferrer">
              <img src={igIcon} alt="Instagram" className='footer__column-media-icon'/>
            </a>
          </div>
         </div>
        
        </div>
        <p className='footer__copyrights'>Copyrights &copy; 2023 - {date.getFullYear()} BYTEBUILDERS</p>
      </footer>
      </>
    );
  }
  
export default Footer;