import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import LanguageDetector

import translationEN from './translations/locales/en/translation.json';
import translationPL from './translations/locales/pl/translation.json';
import translationDE from './translations/locales/de/translation.json';

// Zasoby tłumaczeń.
const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  de: {
    translation: translationDE,
  }
};

i18n
  // inicjalizuje react-i18next
  .use(initReactI18next)
  // Użyj LanguageDetector
  .use(LanguageDetector)
  .init({
    resources,
    // Usunięto lng, ponieważ LanguageDetector automatycznie ustawi język
    fallbackLng: 'pl', // Język zapasowy, na wypadek gdyby automatyczne wykrywanie zawiodło
    detection: {
      // Opcje konfiguracyjne dla detektora języka
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Kolejność wykrywania
      caches: ['localStorage', 'cookie'], // Gdzie zapisywać wykryty język
      checkWhitelist: true, // Sprawdź, czy język należy do listy dozwolonych 'resources'
    },
    debug: false, // Wyłącz w produkcji
    keySeparator: false, // nie używamy kluczy w formie paths
    interpolation: {
      escapeValue: false, // niepotrzebne dla Reacta, ponieważ jest on bezpieczny domyślnie
    },
    whitelist: ['en', 'pl', 'de'], // Ogranicza możliwość wyboru do zdefiniowanych języków
  });

export default i18n;