import { NavLink } from 'react-router-dom';
import WebIcon from '../../../../../assets/images/globe.svg'
import ShopIcon from '../../../../../assets/images/shop.svg'
import CameraIcon from '../../../../../assets/images/camera.svg'
import { useTranslation } from 'react-i18next';

function Offer() {
    const { t } = useTranslation();

    return (
        <section className="offer" id='offer'>
            <div className="offer__inner">
                <h2 className="offer__title">{t('offers_title')}</h2>
                <div className="offer__wrapper">

                    <div className="offer__item">
                        <div className="offer__item-column-1">
                            <img className='offer__item-icon' src={WebIcon} alt="Strony www"/>
                            <h3 className="offer__item-title">{t('offers_item1_title')}</h3>
                            <ul className="offer__list">
                                <li className="offer__list-item">{t('offers_item1_listitem1')}</li>
                                <li className="offer__list-item">{t('offers_item1_listitem2')}</li>
                                <li className="offer__list-item">{t('offers_item1_listitem3')}</li>
                                <li className="offer__list-item">{t('offers_item1_listitem4')}</li>
                                <li className="offer__list-item">{t('offers_item1_listitem5')}</li>
                            </ul>
                        </div>
                        <div className="offer__item-column-2">
                            <NavLink to="/strony-www" className="offer__item-link">{t('offers_item1_btn')}</NavLink>
                        </div>
                    </div>

                    <div className="offer__item">
                        <div className="offer__item-column-1">
                            <img className='offer__item-icon' src={ShopIcon} alt="Sklepy internetowe"/>
                            <h3 className="offer__item-title">{t('offers_item2_title')}</h3>
                            <ul className="offer__list">
                                <li className="offer__list-item">{t('offers_item2_listitem1')}</li>
                                <li className="offer__list-item">{t('offers_item2_listitem2')}</li>
                                <li className="offer__list-item">{t('offers_item2_listitem3')}</li>
                            </ul>
                        </div>
                        <div className="offer__item-column-2">
                            <NavLink to="/sklepy-internetowe" className="offer__item-link">{t('offers_item2_btn')}</NavLink>
                        </div>
                    </div>

                    <div className="offer__item">
                        <div className="offer__item-column-1">
                            <img className='offer__item-icon' src={CameraIcon} alt="Kreacje graficzne"/>
                            <h3 className="offer__item-title">{t('offers_item3_title')}</h3>
                            <ul className="offer__list">
                                <li className="offer__list-item">{t('offers_item3_listitem1')}</li>
                                <li className="offer__list-item">{t('offers_item3_listitem2')}</li>
                                <li className="offer__list-item">{t('offers_item3_listitem3')}</li>
                            </ul>
                        </div>
                        <div className="offer__item-column-2">
                            <NavLink to="/kreacje-graficzne" className="offer__item-link">{t('offers_item3_btn')}</NavLink>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
  }
  
export default Offer;