import React from 'react';
import HeaderDivider from "../../../Global/components/HeaderDivider";
import useMetaTags from '../../../Global/components/UseMetaTags';
import RowImage from '../../../../assets/images/logo_image.webp';
import RowImageSecond from '../../../../assets/images/reklama_image.webp';
import OgImage from '../../../../assets/images/og_bg.webp';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

function KreacjeGraficzne() {
    const { t } = useTranslation();
    
    const metaTags = useMetaTags(
        t('meta_tag_design_title'),
        t('meta_tag_design_desc'),
        t('meta_tag_design_keywords'),
        'https://bytebuilders.pl/kreacje-graficzne',
        `https://bytebuilders.pl${OgImage}`
    );

    return (
        <>
            {metaTags}
            <section className="offer-item">
                <HeaderDivider />
                <div className="offer-item__inner">
                    <h1 className="offer-item__title">{t('branding_and_visual_consistency')} <span className="offer-item__brandColor">{t('visual_identity_of_the_brand')}</span></h1>
                    <p className="offer-item__desc">{t('company_recognition_desc')}</p>
                    <div className="offer-item__row">
                        <img src={RowImage} alt={t('graphic_creations_logo_alt')} className="offer-item__row-image" />
                        <div className="offer-item__row-content">
                            <h2 className="offer-item__row-title">{t('logo_design_and_visual_identity_title')}</h2>
                            <p className="offer-item__row-desc">{t('logo_design_desc')}</p>
                        </div>
                    </div>
                    <div className="offer-item__row-reverse">
                        <div className="offer-item__row-content">
                            <h2 className="offer-item__row-title">{t('advertising_materials_design_title')}</h2>
                            <p className="offer-item__row-desc">{t('advertising_materials_desc')}</p>
                        </div>
                        <img src={RowImageSecond} alt={t('graphic_creations_advertisement_alt')} className="offer-item__row-image" />
                    </div>
                </div>
            </section>
        </>
    );
}

export default KreacjeGraficzne;
