import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ModalImage({ src, alt }) {
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <>
      <img src={src} alt={alt} onClick={handleOpen} style={{ cursor: 'pointer', width: '100%' }} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: '90svw', 
            height: '90svh', 
            overflow: 'auto', 
            bgcolor: 'background.paper', 
            boxShadow: 24, 
            p: 1,
            borderRadius: 5
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img src={src} alt={alt} style={{ width: '100%', height: '100%' }} />
        </Box>
      </Modal>
    </>
  );
}

export default ModalImage;