import InnovationIcon from '../../../../../assets/images/innovation_icon.svg';
import ProjectIcon from '../../../../../assets/images/project_icon.svg';
import CompanyIcon from '../../../../../assets/images/company_icon.svg';
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t } = useTranslation();

    return (
        <section className="about-us" id="o-nas">
            <div className="about-us__inner">
                <h2 className="about-us__title anim-item">{t('aboutus_title')}</h2>
                <div className="about-us__wrapper">
                    <div className="about-us__column anim-item">
                        <img src={InnovationIcon} alt="Innowacyjne rozwiązania" className="about-us__column-image"/>
                        <h3 className="about-us__column-title">{t('aboutus_column1_title')}</h3>
                        <p className="about-us__column-text">{t('aboutus_column1_text')}</p>
                    </div>
                    <div className="about-us__column anim-item">
                        <img src={ProjectIcon} alt="Twój projekt, nasza pasja" className="about-us__column-image"/>
                        <h3 className="about-us__column-title">{t('aboutus_column2_title')}</h3>
                        <p className="about-us__column-text">{t('aboutus_column2_text')}</p>
                    </div>
                    <div className="about-us__column anim-item">
                        <img src={CompanyIcon} alt="Założenie Naszej działaności" className="about-us__column-image"/>
                        <h3 className="about-us__column-title">{t('aboutus_column3_title')}</h3>
                        <p className="about-us__column-text">{t('aboutus_column3_text')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
export default AboutUs;